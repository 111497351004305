import React from 'react';
import { Link } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Layout from '../components/Layout';
import PageWrapper from '../components/PageWrapper';

const NotFoundPage = () => (
  <Layout>
    <PageWrapper title="404 - Siden eksisterer ikke">
      <Grid container xs={12} alignContent="center" justify="center" spacing={3} style={{ height: '40vh' }}>
        <Grid item md={6} sm={8} xs={12} justify="center">
          <Link to="/">
            <div className="readButton">
              <p>
                Tilbake til hjemmesiden
              </p>
            </div>
          </Link>
        </Grid>
      </Grid>
    </PageWrapper>
  </Layout>
);

export default NotFoundPage;
